<template>
    <v-card>
        <v-card-text class="grey lighten-4" elevation="2">
            <v-row v-if="this.productos.length > 0">
                <v-col cols="12" sm="4">
                    <v-list two-line>
                        <v-subheader>
                            Productos
                            <v-spacer />
                        </v-subheader>
                        <v-divider></v-divider>

                        <template v-for="valProducts in this.productos">
                            <div :key="valProducts.id">
                                <v-list-item>
                                    <v-list-item-content>{{ valProducts.description }} </v-list-item-content>

                                    <v-list-item-action>
                                        <v-btn @click="$router.push(`/logistica/orden-produccion/crear/?idVenta=${idVenta}&/producto/&idProducto=${valProducts.id}`)" small
                                            >Generar</v-btn
                                        >
                                    </v-list-item-action>
                                </v-list-item>
                            </div>
                        </template>
                    </v-list>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col>
                    <v-list two-line>
                        <v-subheader>
                            No tenemos habilitado esta opcion en esta seccion
                            <v-spacer />
                        </v-subheader>
                    </v-list>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {}
    },
    computed: {
        ...mapState('ventas', ['productos', 'idVenta'])
    }
}
</script>
